import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { fetchAuthSession, fetchUserAttributes, getCurrentUser, signOut } from 'aws-amplify/auth'
import Logger from '@/logging/Logger'
import { getUserDetail, userTeam } from '@/clients/User'
import { isNil } from 'lodash'
import { useIntercom } from 'react-use-intercom'
import LoadingCenteredView from '@/components/controls/LoadingCenteredView'
import { Hub } from 'aws-amplify/utils'
import { datadogRum } from '@datadog/browser-rum'
import { permissionCheck } from '@/utils/utils'
import { CAN_MANAGE_TEAM } from 'emporia-shared-frontend'

// Create a context
const UserContext = createContext({
    cognitoUser: null,
    cognitoUserAttributes: null,
    cognitoAuthSession: null,
    userDetails: null,
    teamDetails: null,
    isLoading: true,
    isLoggedIn: false,
    isAdmin: false,
    hasPermission: (permission: string) => false,
    refreshContext: () => {},
})

const UserProvider = ({ children, onLoggedIn, onWarning }) => {
    const [loadingUserContext, setLoadingUserContext] = useState<boolean>(true)
    const [userId, setUserId] = useState(null)
    const [cognitoUser, setCognitoUser] = useState(null)
    const [cognitoUserAttributes, setCognitoUserAttributes] = useState(null)
    const [cognitoAuthSession, setCognitoAuthSession] = useState(null)
    const [userDetails, setUserDetails] = useState(null)
    const [teamDetails, setTeamDetails] = useState(null)
    const [isAdmin, setIsAdmin] = useState(false)
    const { update, boot } = useIntercom()
    // Trigger the waterfall
    const refreshUserContext = () => {
        setLoadingUserContext(true)
        setCognitoUser(null)
        setCognitoUserAttributes(null)
        setCognitoAuthSession(null)
        setUserDetails(null)
        setTeamDetails(null)
    }

    const hasPermission = (permission: string) => {
        return permissionCheck(userDetails, permission)
    }

    const context = {
        cognitoUser,
        cognitoUserAttributes,
        cognitoAuthSession,
        teamDetails,
        userDetails,
        isLoading: loadingUserContext,
        isLoggedIn: !isNil(cognitoUser),
        isAdmin,
        hasPermission,
        refreshContext: refreshUserContext,
    }

    useEffect(() => {
        boot()
    }, [])

    useEffect(() => {
        if (!loadingUserContext) {
            return
        }
        getCurrentUser()
            .then((user) => {
                setCognitoUser(user)
                setUserId(user.userId)
            })
            .catch((err) => {
                Logger.info('User not logged in when checking auth state from coggy...expected', err)
                setCognitoUser(null)
                setLoadingUserContext(false)
            })
    }, [loadingUserContext])

    useEffect(() => {
        if (isNil(cognitoUser)) {
            return
        }
        fetchUserAttributes()
            .then((attributes) => {
                setCognitoUserAttributes(attributes)
                datadogRum.setUser({
                    ...attributes,
                })
                update({
                    email: attributes.email,
                    name: attributes.given_name + ' ' + attributes.family_name,
                    customAttributes: {
                        team_name: attributes['custom:team'],
                    },
                })
            })
            .catch((err) => {
                Logger.error('Got an error when fetching user attributes from coggy', err)
                setCognitoUserAttributes(null)
                signOut()
            })
    }, [cognitoUser])

    useEffect(() => {
        if (isNil(cognitoUser)) {
            return
        }
        fetchAuthSession()
            .then((auth) => {
                auth.tokens.accessToken.payload
                const userGroups: any = auth.tokens.accessToken.payload['cognito:groups']
                setIsAdmin(userGroups?.includes('Admin'))
                setCognitoAuthSession(auth)
            })
            .catch((err) => {
                Logger.error('Got an error when fetching auth session from coggy', err)
                setCognitoAuthSession(null)
                signOut()
            })
    }, [cognitoUser])

    useEffect(() => {
        if (isNil(cognitoUser)) {
            return
        }
        getUserDetail(userId)
            .then((response) => {
                setUserDetails(response)
            })
            .catch((err) => {
                Logger.error('Got an error when fetching user details from platform', err)
                setUserDetails(null)
            })
    }, [cognitoUser])

    useEffect(() => {
        if (isNil(cognitoUser)) {
            return
        }
        userTeam(userId)
            .then((result: any) => {
                setTeamDetails(result.teamDetails)
            })
            .catch((err) => {
                Logger.error('Got an error when fetching team details from platform', err)
                setTeamDetails(null)
            })
    }, [cognitoUser])

    useEffect(() => {
        if (
            isNil(cognitoUser) ||
            isNil(cognitoUserAttributes) ||
            isNil(cognitoAuthSession) ||
            isNil(userDetails) ||
            isNil(teamDetails)
        ) {
            return
        }
        setLoadingUserContext(false)
        onLoggedIn(context)
    }, [cognitoUser, cognitoUserAttributes, cognitoAuthSession, userDetails, teamDetails])

    useEffect(() => {
        if (isNil(teamDetails)) {
            return
        }
        const paymentValid = teamDetails.paymentValid === undefined || teamDetails.paymentValid
        const licenseExpire = teamDetails.licenseExpire
        const currentDate = new Date()
        const licenseExpireDate = new Date(licenseExpire)
        const isExpired = currentDate > licenseExpireDate
        const difference = licenseExpireDate.valueOf() - currentDate.valueOf()
        const isWarning = difference > 0 && difference < 2628000000 // 1 month in milliseconds
        if (isWarning) {
            onWarning(
                `Your license expires on ${licenseExpire}. Please contact your Emporia account representative to renew your license.`
            )
        } else if (isExpired) {
            onWarning(
                'Your license has expired. Please contact your Emporia account representative to renew your license.'
            )
        } else if (!paymentValid) {
            onWarning('Your payment method is invalid. Please update your payment information in account settings.')
        }
    }, [teamDetails])

    const loginLogoutHandler = useCallback(
        (event) => {
            if (event.payload.event === 'signedIn') {
                setLoadingUserContext(true)
            } else if (event.payload.event === 'signedOut') {
                setLoadingUserContext(false) // Make sure we don't try to load context again until we sign in
                setCognitoUser(null)
                setCognitoUserAttributes(null)
                setCognitoAuthSession(null)
                setUserDetails(null)
                setTeamDetails(null)
            }
        },
        [
            setLoadingUserContext,
            setCognitoUser,
            setCognitoUserAttributes,
            setCognitoAuthSession,
            setUserDetails,
            setTeamDetails,
        ]
    )

    // Handle login/signup events
    useEffect(() => {
        const cancelListen = Hub.listen('auth', loginLogoutHandler) // listen for login/signup events
        return () => {
            cancelListen()
        }
    }, [loginLogoutHandler])

    return (
        <UserContext.Provider value={context}>
            {loadingUserContext ? <LoadingCenteredView /> : children}
        </UserContext.Provider>
    )
}

export const useUserContext = () => useContext(UserContext)
export default UserProvider

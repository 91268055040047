import { Loader } from '@mantine/core'
import React from 'react'

type SpinnerConfig = {
    style?: object
    message?: string
    size?: 'xs' | 'sm' | 'md' | 'lg'
}

export const LoadingIndicator = ({ style, size }: SpinnerConfig) => {
    return <Loader style={style} size={size} />
}
export default LoadingIndicator

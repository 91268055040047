import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/table/lib/css/table.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import { FocusStyleManager } from '@blueprintjs/core'
import React, { useEffect } from 'react'
import { IntercomProvider } from 'react-use-intercom'
import { datadogRum } from '@datadog/browser-rum'
import UserContext from '@/contexts/UserContext'
import RouteGuard from '@/components/app/RouteGuard'
import { isNil } from 'lodash'

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID

const ResearchUserRoute = ({ children, setWarningMessage, setShowNavBar }) => {
    useEffect(() => {
        FocusStyleManager.onlyShowFocusOnTabs()
        datadogRum.setGlobalContextProperty('session-type', 'research-user')
    }, [])

    const onLoggedInOccured = (context) => {
        if (context.isLoggedIn) setShowNavBar(true)
    }

    return (
        <IntercomProvider appId={INTERCOM_APP_ID}>
            <UserContext onWarning={setWarningMessage} onLoggedIn={onLoggedInOccured}>
                <RouteGuard>{children}</RouteGuard>
            </UserContext>
        </IntercomProvider>
    )
}

export default ResearchUserRoute

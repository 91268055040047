import { apiGet, apiPost } from 'emporia-shared-frontend'

const resourceName = 'Users'
const resourcePath = '/users'
const addUserPath = '/team/add'
const teamUsersPath = '/team/users'
const teamUsagePath = '/team/usage'
const teamCreatePath = '/team/create'

export const userTeam = (userId: string) => {
    return apiGet(resourceName, resourcePath, {
        queryStringParameters: {
            userId,
        },
    })
}

export const getTeamDetailed = (teamId: string) => {
    return apiGet(resourceName, resourcePath, {
        queryStringParameters: {
            fetchType: 'teamDetailed',
            teamId,
        },
    })
}

export const updateUserTeam = (userId: string, teamDisplayName: string) => {
    return apiPost(resourceName, resourcePath, {
        body: {
            userId,
            teamDisplayName,
        },
    })
}

export const createTeam = (teamDisplayName, stripeCustomerId, stripeEmail) => {
    return apiPost(resourceName, teamCreatePath, {
        body: {
            teamName: teamDisplayName,
            stripeCustomerId,
            stripeEmail,
        },
    })
}

//userId is current users id...all other fields are for the user to add
export const addTeamUser = (
    userId: string,
    email: string,
    firstName: string,
    lastName: string,
    canManageTeam: boolean,
    canManageBilling: boolean,
    canCreateProjects: boolean,
    canViewIncentives: boolean,
    teamId?: string
) => {
    const body = {
        email,
        firstName,
        lastName,
        canManageTeam,
        canManageBilling,
        canCreateProjects,
        canViewIncentives,
    }

    if (!!userId) {
        body['userId'] = userId
    }
    if (!!teamId) {
        body['teamId'] = teamId
    }

    return apiPost(resourceName, addUserPath, {
        body,
    })
}

export const getTeamUsers = (teamId: string) => {
    return apiGet(resourceName, teamUsersPath, {
        queryStringParameters: {
            teamId,
        },
    })
}

export const getTeamUsage = (teamId: string) => {
    return apiGet(resourceName, teamUsagePath, {
        queryStringParameters: {
            teamId,
        },
    })
}

export const getTeamProductDataUser = (team: string) => {
    return apiGet(resourceName, resourcePath, {
        queryStringParameters: {
            team: team,
            fetchType: 'product',
        },
    })
}

export const getTeamBillingAccount = (teamId: string) => {
    return apiGet(resourceName, resourcePath, {
        queryStringParameters: {
            teamId,
            fetchType: 'billing_account',
        },
    })
}

export const getTeamSubProductData = (teamId: string) => {
    return apiGet(resourceName, resourcePath, {
        queryStringParameters: {
            teamId,
            fetchType: 'subproduct',
        },
    })
}

export const updateUserObj = (user: string) => {
    const runType = 'update_user'
    return apiPost(resourceName, resourcePath, {
        body: {
            user,
            runType,
        },
    })
}

export const getUserDetail = (userId: string) => {
    return apiGet(resourceName, resourcePath, {
        queryStringParameters: {
            userId: userId,
            fetchType: 'userDetail',
        },
    })
}

import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useUserContext } from '@/contexts/UserContext'

export default function RouteGuard({ children }) {
    const router = useRouter()
    const { isLoggedIn } = useUserContext()
    const [authorized, setAuthorized] = useState(isLoggedIn)
    const authCheck = (url) => {
        // redirect to login page if accessing a private page and not logged in
        // All blended and survey collection routes are public and not part of this tree of routes
        //     '/survey',
        //     '/survey/qualifiedinterview',
        //     '/survey/qualified',
        //     '/survey/quotafull',
        //     '/survey/terminated',
        //     '/survey/qualityrejected',
        //     '/survey/partial',
        //     '/survey/statuscheck',
        //     '/survey/timeout',
        //     '/sample/collect',
        //     '/sample/collect/identify',
        //     '/sample/collect/execute',
        //     '/sample/collect/error',
        const publicExactPaths = [
            '/login',
            '/forgot_password',
            '/signup',
            '/scheduling',
            '/status/subscribed',
            '/status/unsubscribed',
            '/partner-quotas',
        ]
        const publicStartingPaths = ['/third-party-sampler']
        const path = url.split('?')[0]
        const isAuthorizedOrBypassed =
            isLoggedIn ||
            publicExactPaths.includes(path) ||
            publicStartingPaths.some((startingPath) => path.startsWith(startingPath))
        setAuthorized(isAuthorizedOrBypassed)
        if (!isAuthorizedOrBypassed) {
            router.push({
                pathname: '/login',
                query: { returnUrl: router.asPath },
            })
        }
    }

    useEffect(() => {
        authCheck(router.asPath)
    }, [isLoggedIn])

    useEffect(() => {
        // on initial load - run auth check
        authCheck(router.asPath)
        // on route change complete - run auth check
        router.events.on('routeChangeComplete', authCheck)
        // unsubscribe from events in useEffect return function
        return () => {
            router.events.off('routeChangeComplete', authCheck)
        }
    }, [])

    return authorized && children
}

import LoadingIndicator from './LoadingIndicator'
import { spacing } from '../../styles/Theme'
import { Center } from '@mantine/core'

const LoadingCenteredView = ({ message = null }) => (
    <Center sx={{ flex: 1, flexDirection: 'column' }}>
        <LoadingIndicator style={{ marginTop: spacing(), marginBottom: spacing() }} />
        {message}
    </Center>
)

export default LoadingCenteredView
